import React from "react"
import { Helmet } from "react-helmet"
import { Container } from "react-bootstrap"
import Layout from "../components/layout"
import "../styles/general-styles.css"
import SunLogo from "../images/sunxtreme-favicon.png"

export default function TermsOfUse() {
  return (
    <div className="page-background">
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="description" content="Sunxtreme terms of use." />
          <meta name="author" content="Sean Rooney" />
          <link rel="icon" href={SunLogo} type="image/gif" sizes="16x16"></link>
          <link rel="canonical" href="https://sunxtreme.com/terms-of-use" />
          <title>
            Terms of Use -SunXtreme Solar Pool Heating Systems | Solar Pool
            Panels
          </title>
        </Helmet>
        <Container className="container-background">
          <br/>
          <h1 className="terms-of-use-header">Terms of Use</h1>
          <br />
          <h6>
            Welcome to our website. If you continue to browse and use this
            website you are agreeing to comply with and be bound by the
            following terms and conditions of use, which together with our
            privacy policy govern SunXtreme's relationship with you in relation
            to this website. The term ‘COMPANY’ or ‘us’ or ‘we’ refers to the
            owner of the website whose registered office . The term ‘you’ refers
            to the user or viewer of our website. The use of this website is
            subject to the following terms of use:
          </h6>
          <br />
          <div>
            • The content of the pages of this website is for your general
            information and use only. It is subject to change without notice.
          </div>
          <div>
            • Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials found or offered on
            this website for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors and we
            expressly exclude liability for any such inaccuracies or errors to
            the fullest extent permitted by law.
          </div>
          <div>
            • Your use of any information or materials on this website is
            entirely at your own risk, for which we shall not be liable. It
            shall be your own responsibility to ensure that any products,
            services or information available through this website meet your
            specific requirements.
          </div>
          <div>
            • This website contains material which is owned by or licensed to
            us. This material includes, but is not limited to, the design,
            layout, look, appearance and graphics. Reproduction is prohibited
            other than in accordance with the copyright notice, which forms part
            of these terms and conditions.
          </div>
          <div>
            • All trademarks reproduced in this website which are not the
            property of, or licensed to, the operator are acknowledged on the
            website.
          </div>
          <div>
            • Unauthorised use of this website may give rise to a claim for
            damages and/or be a criminal offense.
          </div>
          <div>
            • From time to time this website may also include links to other
            websites. These links are provided for your convenience to provide
            further information. They do not signify that we endorse the
            website(s). We have no responsibility for the content of the linked
            website(s).
          </div>
          <div>
            • You may not create a link to this website from another website or
            document without SunXtreme's prior written consent.
          </div>
          <div>
            • Your use of this website and any dispute arising out of such use
            of the website is subject to the laws of the United States of
            America.
          </div>
          <br />
          <div>SunXtreme</div>
          <div>Heliocol Green Energy, Inc.</div>
          <div>2305 W. Huntington Dr.</div>
          <div>Tempe, AZ 85282</div>
          <div>800-366-6862</div>
          <div>info@heliocolwest.com</div>
          <br />
        </Container>
      </Layout>
    </div>
  )
}
